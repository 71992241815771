import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Coach } from '@/types/Content/Coaching';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Modal from '@/widgets/Modal';
import CustomAvatar from '@/components/CustomAvatar';
import { CalendlyEmbeded } from '@/components/CalendlyEmbeded';
import { stopPropagation } from '@/utils/common';
import AuthStore from '@/stores/AuthStore';
import { Button } from '@/components/Button/Button';
import TextEditorHTML from '@/components/TextEditorHTML';

import { useInitChat } from '@/hooks/useInitChat';
import { color } from 'theme.material';
import Icon from '@/components/icons/Icon';
import { CoachingStore } from '@/stores/index';
import { ModalWrapper, StyledAvatar, StyledVideo } from './HomeTab.styles';
import ConsultantCoPay from './components/ConsultantCoPay';

type Props = {
  coachSlug: Coach['slug'];
  closeModal: () => void;
  extraCoaches?: Array<Coach>;
  setUnlockModalOpened?: React.Dispatch<React.SetStateAction<boolean>>;
  modalScheduleOpened?: boolean;
  setModalScheduleOpened?: React.Dispatch<React.SetStateAction<boolean>>;
  autoApplyScheduleUrl?: boolean;
  setAutoApplyScheduleUrl?: React.Dispatch<React.SetStateAction<boolean>>;
};

const imageWidth = 40;

const MainContentConsultantModal = ({
  coachSlug,
  closeModal,
  extraCoaches,
  setUnlockModalOpened,
  modalScheduleOpened,
  setModalScheduleOpened,
  autoApplyScheduleUrl,
  setAutoApplyScheduleUrl,
}: Props) => {
  const theme = useTheme();
  const matchesSmUp = useMediaQuery(`${theme.breakpoints.up('sm')}`);
  const isMobile = !matchesSmUp;

  const { coaches: coachesFromStore } = CoachingStore;
  const coaches = useMemo(() => coachesFromStore?.concat(extraCoaches || []), [coachesFromStore, extraCoaches]);

  const coach = useMemo(() => coaches?.find((el) => el.slug === coachSlug), [
    coachSlug,
    coaches,
  ]);

  const initChat = useInitChat({
    message: coach?.chatInitMessage,
    slug: coach?.slug,
  });

  const isVideo = !!coach?.longVideo;
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    setShowVideo(false);
  }, [coach]);

  const padding = { xs: theme.spacing(6), md: theme.spacing(8) };
  const showButtonsForFreeUsers = true;

  const onAutoApplyUrl = useCallback(() => setAutoApplyScheduleUrl?.(false), [
    setAutoApplyScheduleUrl,
  ]);

  const calendlyCmp = coach?.calendlyLink ? (
    <CalendlyEmbeded
      color="primary"
      small
      url={coach?.calendlyLink}
      sx={{
        px: theme.spacing(isVideo ? 6 : 2),
        fontSize: theme.spacing(3.5),
        maxWidth: matchesSmUp ? theme.spacing(imageWidth * 1.2) : 'unset',
        textTransform: 'none',
      }}
      disableClick={CoachingStore.loading}
      opened={modalScheduleOpened}
      setOpened={setModalScheduleOpened}
      autoApplyUrl={autoApplyScheduleUrl}
      onAutoApplyUrl={onAutoApplyUrl}
      // onClose={closeModal}
    >
      Schedule a Call
    </CalendlyEmbeded>
  ) : null;

  return (
    <Modal
      isOpen={!!coach}
      handleClose={closeModal}
      closeOnBackClick
      useOutsideCloseButton
      overlayStyle={{ marginTop: 0, marginLeft: 0 }}
    >
      <ModalWrapper
        onClick={stopPropagation}
        onScroll={stopPropagation}
        sx={{ padding: isVideo ? 'unset' : padding }}
      >
        <Stack
          direction={isVideo ? 'column' : 'row'}
          flexWrap={isVideo ? 'nowrap' : 'wrap'}
          alignItems="flex-start"
          sx={{
            gap: 6,
            ...(isVideo
              ? {
                  overflowX: 'hide',
                  overflowY: 'auto',
                  maxHeight: '90vh',
                }
              : {}),
          }}
        >
          {isVideo && (
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                paddingTop: '55%',
                width: '100%',
                background:
                  'radial-gradient(73.42% 73.42% at 37.22% 82.89%, #CBF4EA 0%, #599BB0 100%)',
              }}
            >
              <StyledAvatar
                url={coach?.homepageImage || coach?.image}
                style={{ cursor: 'pointer' }}
              />
              {showVideo ? (
                <StyledVideo controls autoPlay width="100%">
                  <source src={coach.longVideo} type="video/mp4" />
                </StyledVideo>
              ) : (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    color: color.grey[50],
                    backgroundColor: '#00000033',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowVideo(true)}
                >
                  <Icon iconName="play" style={{ fontSize: '32px' }} />
                </Stack>
              )}
            </Box>
          )}
          <Stack
            sx={{
              gap: 4,
              ...(matchesSmUp
                ? { width: theme.spacing(imageWidth) }
                : { flex: 1 }),
              ...(isVideo ? { paddingX: padding, width: '100%' } : null),
            }}
            direction={matchesSmUp && !isVideo ? 'column' : 'row'}
            flexWrap="wrap"
          >
            {!isVideo && (
              <CustomAvatar
                size={{ desktop: imageWidth, mobile: imageWidth }}
                fontSize={{ desktop: 20, mobile: 20 }}
                name={coach?.fullName}
                image={coach?.image}
                variant="circular"
              />
            )}
            <Stack
              direction={isVideo ? 'row' : 'column'}
              flexWrap={isVideo ? 'wrap' : 'unset'}
              gap={4}
              sx={
                matchesSmUp && !isVideo
                  ? undefined
                  : {
                      flex: `1 1 ${theme.spacing(imageWidth)}`,
                      pr: isVideo ? 'unset' : theme.spacing(8),
                    }
              }
            >
              <Box sx={{ flex: isVideo ? '1 1 10rem' : 'unset' }}>
                <Typography
                  lineHeight="1.4 !important"
                  variant="h4"
                  fontSize={isVideo ? theme.spacing(5.75) : 'unset'}
                  color={
                    isVideo
                      ? theme.palette.grey[700]
                      : theme.palette.primary.dark
                  }
                >
                  {coach?.fullName}
                </Typography>
                <Typography
                  lineHeight="1.3"
                  variant="body2"
                  color={
                    isVideo
                      ? theme.palette.primary.main
                      : theme.palette.grey[400]
                  }
                >
                  {coach?.subTitle}
                </Typography>
              </Box>

              <Stack
                direction={isVideo && !isMobile ? 'row' : 'column'}
                justifyContent={isVideo ? 'flex-end' : 'unset'}
                alignItems={isMobile || !isVideo ? 'stretch' : 'center'}
                flexWrap={isVideo ? 'wrap' : 'unset'}
                sx={{ flex: isVideo ? '1.5 1 15rem' : 'unset' }}
                gap={4}
              >
                {!AuthStore.isLoggedIn ? null : !AuthStore.isSponsoredUser &&
                  !showButtonsForFreeUsers ? (
                  <Button
                    small
                    color="primary"
                    variant="contained"
                    onClick={() => setUnlockModalOpened?.(true)}
                  >
                    Unlock
                  </Button>
                ) : (
                  <>
                    <ConsultantCoPay
                      price={coach?.calendlyEventPrice}
                      showButtonsForFreeUsers={showButtonsForFreeUsers}
                    />
                    {isVideo ? null : calendlyCmp}
                    {coach?.chatInitMessage ? (
                      <Button
                        variant="outlined"
                        small
                        onClick={(e) => {
                          stopPropagation(e);
                          initChat();
                        }}
                      >
                        Start Chat
                      </Button>
                    ) : null}
                    {isVideo ? calendlyCmp : null}
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Box
            sx={{
              color: theme.palette.grey[500],
              ...(isVideo
                ? { paddingX: padding, width: '100%' }
                : {
                    flex: `1 1 ${theme.spacing(70)}`,
                    overflowY: 'auto',
                    maxHeight: matchesSmUp ? '60vh' : '40vh',
                  }),
            }}
          >
            {typeof coach?.bio === 'string' ? (
              <Typography variant="body2">{coach?.bio}</Typography>
            ) : (
              <TextEditorHTML items={coach?.bio?.all} />
            )}
          </Box>
        </Stack>
      </ModalWrapper>
    </Modal>
  );
};

export default observer(MainContentConsultantModal);
