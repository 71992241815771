import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Stack, SxProps, useTheme } from '@mui/material';
import {
  HeaderWrapper,
  LogoWrapper,
} from '@/domains/NewHome/components/HeroSection/HeroSection.styles';
import { Logo } from '@/components/Logo';
import { getTutorialStepId } from '@/stores/TutorialStore';
import AuthModalStore from '@/stores/AuthModalStore';
import { Container } from '@/components/layout';
import { useResponsive } from '@/hooks/useResponsive';
import { AuthStore } from '@/stores/index';

const Header = ({ darkLogo = false }: { darkLogo?: boolean }) => {
  const isMobile = useResponsive('down', 'sm');
  const theme = useTheme();
  const [headerScrolledOut, setHeaderScrolledOut] = useState(false);
  const [sticky, setSticky] = useState(false);

  const [animEnabled, setAnimEnabled] = useState(false);
  const timeoutRef = useRef<any>(null);
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(
      () => setAnimEnabled(headerScrolledOut),
      10,
    );
  }, [headerScrolledOut]);

  useEffect(() => {
    if (document.addEventListener) {
      const handler = (e) => {
        const position = window.scrollY;
        setHeaderScrolledOut(position > 100);
        setSticky(position > 120);
      };

      document.addEventListener('scroll', handler, { passive: true });
      return () => document.removeEventListener('scroll', handler);
    }
    return () => null;
  }, [isMobile]);

  const buttonSx: SxProps = {
    padding: { xs: theme.spacing(2), sm: theme.spacing(2, 5) },
    height: `${theme.spacing(9)} !important`,
    textTransform: 'none',
    // backgroundColor: '#46959c',
    textDecoration: 'underline',
    minWidth: { xs: theme.spacing(8), sm: theme.spacing(11) },
    span: {
      marginRight: '0 !important',
    },
    color: theme.palette.primary[900],
  };

  return (
    <HeaderWrapper
      sticky={sticky}
      headerScrolledOut={headerScrolledOut}
      animEnabled={animEnabled}
    >
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={2}
          paddingTop={9}
          paddingBottom={sticky ? 3 : 4}
          // position="relative"
          // zIndex={11}
        >
          <LogoWrapper id={getTutorialStepId(0)}>
            <Logo
              img={
                isMobile
                  ? 'logo-first-letter'
                  : sticky || darkLogo
                  ? 'logo'
                  : 'logo-white'
              }
              width={isMobile ? '37' : undefined}
            />
          </LogoWrapper>

          {!AuthStore.isLoggedIn && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
              flex="1 0 222px"
            >
              <Button
                variant="text"
                onClick={() => AuthModalStore.showModal('login')}
                sx={buttonSx}
              >
                Log In
              </Button>
              <Box
                sx={{
                  height: theme.spacing(7),
                  borderLeftWidth: 1,
                  borderColor: theme.palette.primary[900],
                }}
              />
              <Button
                variant="text"
                onClick={() => AuthModalStore.showModal('signup')}
                sx={buttonSx}
              >
                Create Account
              </Button>
            </Stack>
          )}
        </Stack>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;
